import { Component, OnInit, OnDestroy, Renderer2, AfterContentChecked } from '@angular/core';
import { PrismicService } from 'src/app/services/prismic.service';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit, OnDestroy, AfterContentChecked {
  public categories = [];
  public uid;
  public product;
  public productSubscriber;
  public pageLoad = false;
  public activeImage;
  public activeButton = 1;
  public accordion = [];
  public timer;
  public load = false;

  constructor(
    private renderer: Renderer2,
    public prismic: PrismicService,
    public router: ActivatedRoute,
    public meta: Meta,
    public title: Title
  ) {
    this.product = undefined;
    this.accordion['informacoes'] = true;
    const PrismicDOM = require('prismic-dom');
    this.router.params.subscribe((params) => {
      this.pageLoad = true;
      this.uid = params.uid;
      this.prismic.loadContent('categoria_produto'); 
      this.prismic.loadContent(
        'produto2',
        null,
        [
          { type: 'at', key: 'my.produto2.uid', value: this.uid }
        ]
      ); 
      this.productSubscriber = this.prismic.getContent.subscribe(contentResult => {
        this.product = undefined;
        if (contentResult.results) {
          contentResult.results
            .filter(item => item.type === 'categoria_produto')
            .map(item => {
              this.categories.push(item);
            });
          contentResult.results
            .filter(item => item.type === 'produto2')
            .map(item => {
              for (let i in item.data) {
                if (item.data[i]) {
                  this.meta.removeTag('name="' + i + '"');
                  this.meta.addTag({ name: i, content: item.data[i] });
                }
              }
              this.title.setTitle(item.data.meta_title);

              const categoryValue = this.categories
                .filter(category => category.id === item.data.categoria.id)
                .map(category => {
                  return category.data.nome[0].text;
                });
              item.data.categoryValue = categoryValue[0];
              item.data.ingredientesText = PrismicDOM.RichText.asHtml(item.data.ingredientes)
              if (item.data.foto2.url) {
                this.activeImage = item.data.foto2.url;
                this.setTimer(true);
              } else {
                this.activeImage = item.data.foto_produto.url;
              }
              this.product = item;
              this.pageLoad = false;
            });
        }
      });
    }); 
  }

  toogleImage(image, index) {
    if (!this.product) {
      return false;
    }
    this.activeButton = index;
    this.activeImage = this.product.data[image].url;
  }

  ngOnInit() {
    this.product = undefined;
    this.pageLoad = true;
    this.renderer.addClass(document.body, 'page-produtos-single');
    this.renderer.removeClass(document.body, 'page-metodo');
    this.renderer.removeClass(document.body, 'page-produtos');
    this.renderer.removeClass(document.body, 'page-onde-estamos');
    this.renderer.removeClass(document.body, 'page-mynce-talks');
    this.renderer.removeClass(document.body, 'page-receitas');
    this.renderer.removeClass(document.body, 'page-receitas-single');
    this.renderer.removeClass(document.body, 'page-mynce-talks-single');
  }

  ngAfterContentChecked() {
    if (!this.load) {
      let canvas = document.querySelectorAll( 'canvas' )[0];
      if (canvas) {
        canvas.remove();
      }
      window['CanvasSlideshow']({
        sprites: [ 'assets/img/bg_body_page-produtos_02.png' ],
        displacementImage: 'https://raw.githubusercontent.com/codrops/LiquidDistortion/master/img/dmaps/2048x2048/clouds.jpg',
        autoPlay: true,
        autoPlaySpeed: [4, 3],
        displaceScale: [5000, 10000],
        interactive: true,
        interactionEvent: 'click', // 'click', 'hover', 'both' 
        displaceAutoFit: false,
        dispatchPointerOver: true // restarts pointerover event after click 
      });
      this.load = true;
    }
  }

  ngOnDestroy() {
    this.product = undefined;
    this.productSubscriber.unsubscribe();
  }

  toggleAccordion(item) {
    this.accordion[item] = !this.accordion[item];
  }
  
  setTimer(status){
    if (this.product && !this.product.data.foto2.url)
      return false;
    if (status) {
      this.timer = setInterval(() => { 
        if (this.activeButton == 1) {
          this.toogleImage('foto_produto', 2);
        } else {
          this.toogleImage('foto2', 1);
        }
      }, 6000);
    } else {
      clearInterval(this.timer);
    }
  }

}
