import { Component, OnInit, Renderer2, OnDestroy, AfterContentChecked, HostBinding } from '@angular/core';
import { PrismicService } from 'src/app/services/prismic.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-recipes',
  templateUrl: './recipes.component.html',
  styleUrls: ['./recipes.component.css']
})
export class RecipesComponent implements OnInit, OnDestroy, AfterContentChecked {
  public categories;
  public categorySubscriber;
  public recipes = [];
  public filterStatus = false;
  public buttonMore = false;
  public filter = [];
  public page = 1;
  public totalPages = 0;
  public itemsPerPage = 20;
  public activeCategory = '';
  public receitas;
  public load = false;

  @HostBinding('class.active') activeClass = false;

  constructor(
    public prismic: PrismicService,
    private renderer: Renderer2,
    public meta: Meta,
    public title: Title
  ) {
    this.receitas = [];
    this.recipes = [];
    this.prismic.loadContent(
      'categoria_receita',
      { orderings: '[my.categoria_receita.ordem]' }
    );  
    this.prismic.loadContent(
      'receita',
      { pageSize: this.itemsPerPage, orderings: '[my.receita.ordem]' },
      null,
      'list'
    ); 
    this.prismic.loadContent(
      'receitas'
    ); 
  }

  ngOnInit() {
    this.renderer.addClass(document.body, 'page-receitas');
    this.renderer.removeClass(document.body, 'page-metodo');
    this.renderer.removeClass(document.body, 'page-produtos-single');
    this.renderer.removeClass(document.body, 'page-onde-estamos');
    this.renderer.removeClass(document.body, 'page-mynce-talks');
    this.renderer.removeClass(document.body, 'page-receitas-single');
    this.renderer.removeClass(document.body, 'page-produtos');
    this.renderer.removeClass(document.body, 'page-mynce-talks-single');

    const PrismicDOM = require('prismic-dom');
    this.categorySubscriber = this.prismic.getContent.subscribe(contentResult => {
      if (contentResult.results) {
        if (contentResult.type === 'categoria_receita') {
          this.categories = [];
        }
        if (contentResult.type === 'receita') {
          this.totalPages = contentResult.total_pages;
          if (this.page < this.totalPages) {
            this.buttonMore = true;
          } else {
            this.buttonMore = false;
          }
        }
        contentResult.results
          .filter(item => item.type === 'receitas')
          .map(item => {
            for (let i in item.data) {
              if (item.data[i]) {
                this.meta.removeTag('name="' + i + '"');
                this.meta.addTag({ name: i, content: item.data[i] });
              }
            }
            this.title.setTitle(item.data.meta_title);

            item.data.header.map(x => {
              x.tituloData = PrismicDOM.RichText.asHtml(x.titulo);  
              x.textoData = PrismicDOM.RichText.asHtml(x.texto);  
            })
            this.receitas = item;
          });
        contentResult.results
            .filter(item => item.type === 'categoria_receita')
            .map(item => {
              this.categories.push(item);
            });
        contentResult.results
            .filter(item => item.type === 'receita')
            .map(item => {
              if (this.categories) {
                const categoriaValue = this.categories
                  .filter(categoria => categoria.id === item.data.categoria.id)
                  .map(categoria => {
                    return categoria.data.nome[0].text;
                  });
                item.data.categoryValue = categoriaValue[0];
                this.recipes.push(item);
              }
            });
      }
    });
  }

  ngAfterContentChecked() {
    if (!this.load) {
      let canvas = document.querySelectorAll( 'canvas' )[0];
      if (canvas) {
        canvas.remove();
      }
      window['CanvasSlideshow']({
        sprites: [ 'assets/img/bg_body_page-receitas_02.png' ],
        displacementImage: 'https://raw.githubusercontent.com/codrops/LiquidDistortion/master/img/dmaps/2048x2048/clouds.jpg',
        autoPlay: true,
        autoPlaySpeed: [4, 3],
        displaceScale: [5000, 10000],
        interactive: true,
        interactionEvent: 'click', // 'click', 'hover', 'both' 
        displaceAutoFit: false,
        dispatchPointerOver: true // restarts pointerover event after click 
      });
      this.load = true;
      setTimeout(() => {
        this.activeClass = true;
      }, 400)
    }
  }

  filterRecipes(id) {
    this.filterStatus = false;
    this.buttonMore = false;
    this.page = 1;
    this.filter = [];
    this.recipes = [];
    if (id !== '') {
      this.filter = [
        {
          type: 'at', key: 'my.receita.categoria', value: id
        }
      ];
    }
    this.activeCategory = id;
    this.prismic.loadContent(
      'receita',
      { pageSize: this.itemsPerPage },
      this.filter
    ); 
  }

  ngOnDestroy() {
    this.categorySubscriber.unsubscribe();
  }

  loadMoreRecipes() {
    this.page += 1;
    this.prismic.loadContent(
      'receita',
      { pageSize: this.itemsPerPage, page: this.page },
      this.filter
    ); 
  }
}
