import { Component, OnInit, Renderer2, OnDestroy, AfterContentChecked } from '@angular/core';
import { PrismicService } from 'src/app/services/prismic.service';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-talk',
  templateUrl: './talk.component.html',
  styleUrls: ['./talk.component.css']
})
export class TalkComponent implements OnInit, OnDestroy, AfterContentChecked {
  public categories = [];
  public uid;
  public news;
  public newsSubscriber;
  public pageLoad = false;
  public activeImage;
  public activeButton = 1;
  public accordion = [];
  public timer;
  public load = false;

  constructor(
    public renderer: Renderer2,
    public prismic: PrismicService,
    public router: ActivatedRoute,
    public meta: Meta,
    public title: Title
  ) {
    var PrismicDOM = require('prismic-dom');
    this.router.params.subscribe((params) => {
      this.pageLoad = true;
      this.uid = params.uid;
      this.prismic.loadContent('categoria_noticias'); 
      this.prismic.loadContent(
        'noticias',
        null,
        [
          { type: 'at', key: 'my.noticias.uid', value: this.uid }
        ]
      ); 
      this.newsSubscriber = this.prismic.getContent.subscribe(contentResult => {
        this.news = undefined;
        if (contentResult.results) {
          contentResult.results
            .filter(item => item.type === 'categoria_noticias')
            .map(item => {
              this.categories.push(item);
            });
          contentResult.results
            .filter(item => item.type === 'noticias')
            .map(item => {
              for (let i in item.data) {
                if (item.data[i]) {
                  this.meta.removeTag('name="' + i + '"');
                  this.meta.addTag({ name: i, content: item.data[i] });
                }
              }
              this.title.setTitle(item.data.meta_title);
  
              const categoryValue = this.categories
                .filter(category => category.id === item.data.categoria.id)
                .map(category => {
                  return category.data.nome[0].text;
                });
              item.data.categoryValue = categoryValue[0];
              item.disclaimer = PrismicDOM.RichText.asHtml(item.data.disclaimer)
              item.data.body.map(itemContent => {
                itemContent.content = PrismicDOM.RichText.asHtml(itemContent.primary.texto)
              });
              item.date = 
              this.news = item;
              this.pageLoad = false;
            });
        }
      }); 
    });
  }

  ngOnInit() {
    this.renderer.addClass(document.body, 'page-mynce-talks-single');
    this.renderer.removeClass(document.body, 'page-metodo');
    this.renderer.removeClass(document.body, 'page-produtos-single');
    this.renderer.removeClass(document.body, 'page-onde-estamos');
    this.renderer.removeClass(document.body, 'page-mynce-talks');
    this.renderer.removeClass(document.body, 'page-receitas-single');
    this.renderer.removeClass(document.body, 'page-produtos');
    this.renderer.removeClass(document.body, 'page-receitas');
  }

  ngAfterContentChecked() {
    if (!this.load) {
      let canvas = document.querySelectorAll( 'canvas' )[0];
      if (canvas) {
        canvas.remove();
      }
      window['CanvasSlideshow']({
        sprites: [ 'assets/img/bg_body_page-mynce-talks_02.png' ],
        displacementImage: 'https://raw.githubusercontent.com/codrops/LiquidDistortion/master/img/dmaps/2048x2048/clouds.jpg',
        autoPlay: true,
        autoPlaySpeed: [4, 3],
        displaceScale: [5000, 10000],
        interactive: true,
        interactionEvent: 'click', // 'click', 'hover', 'both' 
        displaceAutoFit: false,
        dispatchPointerOver: true // restarts pointerover event after click 
      });
      this.load = true;
    }
  }

  ngOnDestroy() {
    this.news = undefined;
    this.newsSubscriber.unsubscribe();
  }

}
