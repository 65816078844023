import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { MailService } from 'src/app/services/mail.service';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public tooltipStatus = false;
  public modalStatus = false;
  public statusMenu = false;
  public classeBotao = '';
  public contact: FormGroup;
  public modalSuccessStatus = false;
  public invalid = [];

  constructor(
    public fb: FormBuilder,
    public mailService: MailService,
    public router: Router
  ) {
    this.setForm(); 
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        window.scrollTo(0, 0); 
        this.statusMenu = this.tooltipStatus = false;
      }
      switch (true) {
        case /produto/.test(this.router.url):
          this.classeBotao = 'btn-goblin';
        break;
        case /onde-encontrar/.test(this.router.url):
          this.classeBotao = 'btn-burnt-sienna';
        break;
        case /mynce-talk/.test(this.router.url):
          this.classeBotao = 'btn-affair';
        break;
        case /recipe/.test(this.router.url):
          this.classeBotao = 'btn-indian-khaki';
        break;
        default:
          this.classeBotao = 'btn-celery';
      }
    });
  }

  formSubmit() {
    this.invalid = [];
    if (this.contact.invalid) {
      for (let key in this.contact.controls) {
        if (this.contact.controls[key].value === '') {
          this.invalid[key] = true;
        } else {
          this.invalid[key] = false;
        }
      }
      if (/^([0-9]{2})([0-9]{4,5})([0-9]{4})$/.test(this.contact.controls.telefone.value)) {
        this.invalid['telefone'] = false;
      } else {
        this.invalid['telefone'] = true;
      } 
      if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.contact.controls.email.value)) {
        this.invalid['email'] = false;
      } else {
        this.invalid['email'] = true;
      }
      return false;
    }
    if (/^([0-9]{2})([0-9]{4,5})([0-9]{4})$/.test(this.contact.controls.telefone.value)) {
      this.invalid['telefone'] = false;
    } else {
      this.invalid['telefone'] = true;
      return false;
    } 
    if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.contact.controls.email.value)) {
      this.invalid['email'] = false;
    } else {
      this.invalid['email'] = true;
      return false;
    }
    let message = '<p>Nome: ' + this.contact.controls.nome.value + '</p>';
    message += '<p>E-mail: ' + this.contact.controls.email.value + '</p>';
    message += '<p>Telefone: ' + this.contact.controls.telefone.value + '</p>';
    message += '<p>Cidade: ' + this.contact.controls.cidade.value + '</p>';
    message += '<p>Estado: ' + this.contact.controls.estado.value + '</p>';
    this.mailService.sendMail({email: this.contact.controls.email.value, message: message});
    this.setForm();
    this.toggleModalSuccess(true);
    this.tooltipStatus = false;
    this.modalStatus = false;
  }

  toggleModalSuccess(status) {
    this.modalSuccessStatus = status;
  }

  ngOnInit() {
  }

  toggleTooltip(status) {
    this.setForm();
    this.tooltipStatus = status;
  }

  toggleModal(status) {
    this.setForm();
    this.modalStatus = status;
  }

  toggleMenu(){
    this.statusMenu = !this.statusMenu;
  }

  setForm() {
    this.invalid = [];
    this.contact = this.fb.group({
      nome: new FormControl(''),
      email: new FormControl(''),
      telefone: new FormControl(''),
      cidade: new FormControl(''),
      estado: new FormControl(''),
    }); 
    let telefone = <HTMLInputElement>document.getElementById('telefone');
    if (telefone) {
      telefone.value = '';
    }
    telefone = <HTMLInputElement>document.getElementById('telefoneModal');
    if (telefone) {
      telefone.value = '';
    }
  }

}
