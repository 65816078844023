import { Component, OnInit, Renderer2, Inject, OnDestroy, AfterContentChecked, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MailService } from 'src/app/services/mail.service';
import { NgAnimateScrollService } from 'ng-animate-scroll';
import { PrismicService } from 'src/app/services/prismic.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy, AfterContentChecked {
  public modalStatus = false;
  public contact: FormGroup;
  public modalSuccessStatus = false;
  public invalid = [];
  public accordion = [];
  public homeSubscriber;
  public home;
  public load = false;

  @HostBinding('class.active') activeClass = false;

  constructor(
    public fb: FormBuilder,
    public prismic: PrismicService,
    public mailService: MailService,
    private renderer: Renderer2,
    private animateScrollService: NgAnimateScrollService,
    public meta: Meta,
    public title: Title
  ) {
    this.home = [];
    this.prismic.loadContent(
      'home',
      { orderings: '[my.categoria_produto.ordem]' }
    ); 
    this.setForm(); 
  }

  ngOnInit() {
    this.renderer.addClass(document.body, 'page-metodo');
    this.renderer.removeClass(document.body, 'page-produtos-single');
    this.renderer.removeClass(document.body, 'page-produtos');
    this.renderer.removeClass(document.body, 'page-onde-estamos');
    this.renderer.removeClass(document.body, 'page-mynce-talks');
    this.renderer.removeClass(document.body, 'page-receitas');
    this.renderer.removeClass(document.body, 'page-receitas-single');
    this.renderer.removeClass(document.body, 'page-mynce-talks-single');

    const PrismicDOM = require('prismic-dom');
    this.homeSubscriber = this.prismic.getContent.subscribe(contentResult => {
      if (contentResult.results) {
        contentResult.results
          .filter(item => item.type === 'home')
          .map(item => {
            for (let i in item.data) {
              if (item.data[i]) {
                this.meta.removeTag('name="' + i + '"');
                this.meta.addTag({ name: i, content: item.data[i] });
              }
            }
            this.title.setTitle(item.data.meta_title);

            item.data.header.map(x => {
              x.tituloData = PrismicDOM.RichText.asHtml(x.titulo);  
              x.textoData = PrismicDOM.RichText.asHtml(x.texto);  
            })

            item.data.conheca_o_metodo.map(x => {
              x.tituloData = PrismicDOM.RichText.asHtml(x.titulo);  
              x.subtituloData = PrismicDOM.RichText.asHtml(x.subtitulo);  
              x.textoData = PrismicDOM.RichText.asHtml(x.texto);  
            })

            item.data.titulo_sessao_accordionData = PrismicDOM.RichText.asHtml(item.data.titulo_sessao_accordion);  

            item.data.accordions.map(x => {
              x.tituloData = PrismicDOM.RichText.asHtml(x.titulo);  
              x.textoData = PrismicDOM.RichText.asHtml(x.texto);  
            })

            item.data.sessao_apoio.map(x => {
              x.tituloData = PrismicDOM.RichText.asHtml(x.titulo);  
              x.textoData = PrismicDOM.RichText.asHtml(x.texto);  
            })

            item.data.sessao_etapas.map(x => {
              x.tituloData = PrismicDOM.RichText.asHtml(x.titulo);  
              x.textoData = PrismicDOM.RichText.asHtml(x.texto);  
            })

            item.data.etapas.map(x => {
              x.tituloData = PrismicDOM.RichText.asHtml(x.titulo);  
              x.textoData = PrismicDOM.RichText.asHtml(x.texto);  
            })

            this.home = item;

          });
      }
    });
  }

  ngAfterContentChecked() {
    if (!this.load && window['CanvasSlideshow']) {
      let canvas = document.querySelectorAll( 'canvas' )[0];
      if (canvas) {
        canvas.remove();
      }
      window['CanvasSlideshow']({
        sprites: [ 'assets/img/bg_body_page-metodo_02.png' ],
        displacementImage: 'https://raw.githubusercontent.com/codrops/LiquidDistortion/master/img/dmaps/2048x2048/clouds.jpg',
        autoPlay: true,
        autoPlaySpeed: [4, 3],
        displaceScale: [5000, 10000],
        interactive: true,
        interactionEvent: 'click', // 'click', 'hover', 'both' 
        displaceAutoFit: false,
        dispatchPointerOver: true // restarts pointerover event after click 
      });
      this.load = true;
      setTimeout(() => {
        this.activeClass = true;
      }, 400)
    }
  }

  ngOnDestroy() {
    this.homeSubscriber.unsubscribe();
  }

  aboutScroll() {
    this.animateScrollService.scrollToElement('about', 500);
  }

  toggleModal(status) {
    this.setForm(); 
    this.modalStatus = status;
  }

  toggleModalSuccess(status) {
    this.modalSuccessStatus = status;
  }

  toggleAccordion(item) {
    item.status = !item.status;
  }

  formSubmit() {
    this.invalid = [];
    if (this.contact.invalid) {
      for (let key in this.contact.controls) {
        if (this.contact.controls[key].value === '') {
          this.invalid[key] = true;
        } else {
          this.invalid[key] = false;
        }
      }
      if (/^([0-9]{2})([0-9]{4,5})([0-9]{4})$/.test(this.contact.controls.telefone.value)) {
        this.invalid['telefone'] = false;
      } else {
        this.invalid['telefone'] = true;
      } 
      if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.contact.controls.email.value)) {
        this.invalid['email'] = false;
      } else {
        this.invalid['email'] = true;
      }
      return false;
    }
    if (/^([0-9]{2})([0-9]{4,5})([0-9]{4})$/.test(this.contact.controls.telefone.value)) {
      this.invalid['telefone'] = false;
    } else {
      this.invalid['telefone'] = true;
      return false;
    } 
    if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.contact.controls.email.value)) {
      this.invalid['email'] = false;
    } else {
      this.invalid['email'] = true;
      return false;
    }
    let message = '<p>Nome: ' + this.contact.controls.nome.value + '</p>';
    message += '<p>E-mail: ' + this.contact.controls.email.value + '</p>';
    message += '<p>Telefone: ' + this.contact.controls.telefone.value + '</p>';
    message += '<p>Cidade: ' + this.contact.controls.cidade.value + '</p>';
    message += '<p>Estado: ' + this.contact.controls.estado.value + '</p>';
    this.mailService.sendMail({email: this.contact.controls.email.value, message: message});
    this.modalStatus = false;
    this.setForm();
    this.toggleModalSuccess(true);
  }

  setForm() {
    this.contact = this.fb.group({
      nome: new FormControl(''),
      email: new FormControl(''),
      telefone: new FormControl(''),
      cidade: new FormControl(''),
      estado: new FormControl(''),
    }); 
    let telefone = <HTMLInputElement>document.getElementById('telefone');
    if (telefone) {
      telefone.value = '';
    }
  }
}
