import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './routes/home/home.component';
import { WhereToFindComponent } from './routes/where-to-find/where-to-find.component';
import { ProductsComponent } from './routes/products/products.component';
import { ProductComponent } from './routes/product/product.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { PrismicService } from './services/prismic.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxPhoneMaskBrModule } from 'ngx-phone-mask-br';
import 'hammerjs';
import { RecipesComponent } from './routes/recipes/recipes.component';
import { RecipeComponent } from './routes/recipe/recipe.component';
import { TalksComponent } from './routes/talks/talks.component';
import { TalkComponent } from './routes/talk/talk.component';
import { MomentModule } from 'ngx-moment';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    WhereToFindComponent,
    ProductsComponent,
    ProductComponent,
    HeaderComponent,
    FooterComponent,
    RecipesComponent,
    RecipeComponent,
    TalksComponent,
    TalkComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    MomentModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxPhoneMaskBrModule
  ],
  providers: [
    PrismicService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
