import { Component, OnInit, OnDestroy, Renderer2, AfterContentChecked, HostBinding } from '@angular/core';
import { PrismicService } from 'src/app/services/prismic.service';
import { NgAnimateScrollService } from 'ng-animate-scroll';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit, OnDestroy, AfterContentChecked {
  public categories;
  public subcategoria = [];
  public subcategoriaProduto = [];
  public products = [];
  public filterStatus = false;
  public categorySubscriber;
  public activeCategory = '';
  public activeSubCategory = '';
  public buttonMore = false;
  public filter = [];
  public ids = [];
  public page = 1;
  public totalPages = 0;
  public itemsPerPage = 21;
  public produtos;
  public load = false;

  @HostBinding('class.active') activeClass = false;

  constructor(
    private renderer: Renderer2,
    public prismic: PrismicService,
    private animateScrollService: NgAnimateScrollService,
    public meta: Meta,
    public title: Title
  ) {
    this.products = [];
    this.produtos = [];
    if (window.innerWidth < 789) {
      this.itemsPerPage = 20;
    }
    this.prismic.loadContent(
      'subcategoria_produto',
      { pageSize: 50 }
    ); 
    this.prismic.loadContent(
      'produtos'
    ); 
    this.prismic.loadContent(
      'categoria_produto',
      { orderings: '[my.categoria_produto.ordem]' }
    ); 
    this.prismic.loadContent(
      'produto2',
      { pageSize: this.itemsPerPage, orderings: '[my.produto2.ordem]' },
      null,
      'list'
    ); 
  }

  ngOnInit() {
    this.renderer.addClass(document.body, 'page-produtos');
    this.renderer.removeClass(document.body, 'page-metodo');
    this.renderer.removeClass(document.body, 'page-produtos-single');
    this.renderer.removeClass(document.body, 'page-onde-estamos');
    this.renderer.removeClass(document.body, 'page-mynce-talks');
    this.renderer.removeClass(document.body, 'page-receitas');
    this.renderer.removeClass(document.body, 'page-receitas-single');
    this.renderer.removeClass(document.body, 'page-mynce-talks-single');
    
    const PrismicDOM = require('prismic-dom');
    this.categorySubscriber = this.prismic.getContent.subscribe(contentResult => {
      if (contentResult.results) {
        if (contentResult.type === 'categoria_produto') {
          this.categories = [];
        }
        if (contentResult.type === 'produto2') {
          this.totalPages = contentResult.total_pages;
          if (this.page < this.totalPages) {
            this.buttonMore = true;
          } else {
            this.buttonMore = false;
          }
        }
        contentResult.results
          .filter(item => item.type === 'produtos')
          .map(item => {
            for (let i in item.data) {
              if (item.data[i]) {
                this.meta.removeTag('name="' + i + '"');
                this.meta.addTag({ name: i, content: item.data[i] });
              }
            }
            this.title.setTitle(item.data.meta_title);
            
            item.data.header.map(x => {
              x.tituloData = PrismicDOM.RichText.asHtml(x.titulo);  
              x.textoData = PrismicDOM.RichText.asHtml(x.texto);  
            })
            item.data.sessao.map(x => {
              x.tituloData = PrismicDOM.RichText.asHtml(x.titulo);  
              x.textoData = PrismicDOM.RichText.asHtml(x.texto);  
            })
            this.produtos = item;
          });
        contentResult.results
          .filter(item => item.type === 'subcategoria_produto')
          .map(item => {
            this.subcategoria.push(item);
          });
        contentResult.results
            .filter(item => item.type === 'categoria_produto')
            .map(item => {
              item.sub = this.subcategoria
                .filter(subcategoria => item.id === subcategoria.data.categoria_mae.id)
                .map(subcategoria => {
                  return subcategoria
                });
              this.categories.push(item);
            });
        contentResult.results
            .filter(item => item.type === 'produto2')
            .map(item => {
              if (this.subcategoria.length > 1) {
                this.ids.push(item.id)
                const subcategoriaValue = this.subcategoria
                  .filter(subcategoria => subcategoria.id === item.data.subcategoria.id)
                  .map(subcategoria => {
                    return subcategoria.data.nome[0].text;
                  });
                item.data.categoryValue = subcategoriaValue[0];
                this.products.push(item);
              }
            });
      }
    });
  }

  ngAfterContentChecked() {
    if (!this.load) {
      let canvas = document.querySelectorAll( 'canvas' )[0];
      if (canvas) {
        canvas.remove();
      }
      window['CanvasSlideshow']({
        sprites: [ 'assets/img/bg_body_page-produtos_02.png' ],
        displacementImage: 'https://raw.githubusercontent.com/codrops/LiquidDistortion/master/img/dmaps/2048x2048/clouds.jpg',
        autoPlay: true,
        autoPlaySpeed: [4, 3],
        displaceScale: [5000, 10000],
        interactive: true,
        interactionEvent: 'click', // 'click', 'hover', 'both' 
        displaceAutoFit: false,
        dispatchPointerOver: true // restarts pointerover event after click 
      });
      this.load = true;
      setTimeout(() => {
        this.activeClass = true;
      }, 400)
    }
  }

  ngOnDestroy() {
    this.categorySubscriber.unsubscribe();
  }

  filterProducts(id) {
    this.filterStatus = false;
    this.buttonMore = false;
    this.page = 1;
    this.filter = [];
    this.products = [];
    if (id !== '') {
      this.filter = [
        {
          type: 'at', key: 'my.produto2.categoria', value: id
        }
      ];
    }
    this.activeCategory = id;
    this.prismic.loadContent(
      'produto2',
      { pageSize: this.itemsPerPage },
      this.filter
    ); 
  }

  filterProductsSub(id, categoryId) {
    this.filterStatus = false;
    this.buttonMore = false;
    this.page = 1;
    this.filter = [];
    this.products = [];
    if (id !== '') {
      this.filter = [
        {
          type: 'at', key: 'my.produto2.subcategoria', value: id
        }
      ];
    }
    this.activeCategory = categoryId;
    this.activeSubCategory = id;
    this.prismic.loadContent(
      'produto2',
      { pageSize: this.itemsPerPage },
      this.filter
    ); 
  }

  toggleFilter() {
    this.filterStatus = !this.filterStatus;
  }

  scrollToProducts() {
    this.animateScrollService.scrollToElement('products', 500);
  }

  loadMoreProducts() {
    this.page += 1;
    this.prismic.loadContent(
      'produto2',
      { pageSize: this.itemsPerPage, page: this.page },
      this.filter
    ); 
  }
}
