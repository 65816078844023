import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrismicService {
  public content = new BehaviorSubject<any>([]);
  public apiEndpoint = 'https://myncelife.cdn.prismic.io/api/v2';
  public apiToken = 'MC5YUjVkaVJFQUFDVUE3alg4.77-977-9LloBCF9577-977-9Emvvv73vv70MV--_vR7vv71f77-9Nu-_vW9Sdgdk77-977-9J38';
  public call = [];
  public queryItems = [];

  constructor() {
    this.call = [];
  }

  loadContent(type, opts = null, query = null, refer = null) {
    const Prismic = require('prismic-javascript');

    let predicates = [];
    predicates.push('[at(document.type, "' + type + '")]');
    if (query) {
      for (let item of query) {
        if (!item.number && item.value.indexOf('"') < 0) {
          item.value = '"' + item.value + '"';
        }
        predicates.push('[' + item.type + '(' + item.key + ', ' + item.value + ')]');
      }
    }
    Prismic.getApi(this.apiEndpoint, {accessToken: this.apiToken}).then(function(api) {
      return api.query(
        predicates,
        opts
      );
    }).then(result => {
      result.type = type;
      result.refer = refer;
      this.content.next(result);
    });
  }

  public get getContent(): Observable<any> {
    return this.content.asObservable();
  }
}
