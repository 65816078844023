import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MailService {
  public url = 'https://alrp.com.br/mail.php?referer=mynce';
  public headers: HttpHeaders;
  constructor(
    private http: HttpClient
  ) {
    this.headers = new HttpHeaders(); 
  }

  sendMail(payload) {
    this.headers.append('Content-Type', 'application/json');
    const httpOptions: any = {
      headers: this.headers,
      observe: 'response'
    };
    this.url += "&subject=Consulta via site";
    this.url += "&message=" + payload.message;
    this.url += "&email=" + payload.email;
    let observable = this.http.post.call(
      this.http, 
      this.url, 
      payload, 
      httpOptions
    );

    return observable
      .pipe(
        map((response: HttpResponse<any>) => response.body)
      ).toPromise();
  }
}
