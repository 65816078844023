import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './routes/home/home.component';
import { WhereToFindComponent } from './routes/where-to-find/where-to-find.component';
import { ProductsComponent } from './routes/products/products.component';
import { ProductComponent } from './routes/product/product.component';
import { TalksComponent } from './routes/talks/talks.component';
import { TalkComponent } from './routes/talk/talk.component';
import { RecipesComponent } from './routes/recipes/recipes.component';
import { RecipeComponent } from './routes/recipe/recipe.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'onde-encontrar',
    component: WhereToFindComponent
  },
  {
    path: 'produtos',
    component: ProductsComponent
  },
  {
    path: 'produto/:uid',
    component: ProductComponent
  },
  {
    path: 'mynce-talks',
    component: TalksComponent
  },
  {
    path: 'mynce-talks/:uid',
    component: TalkComponent
  },
  {
    path: 'receitas',
    component: RecipesComponent
  },
  {
    path: 'receita/:uid',
    component: RecipeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
